.layout {
  color: var(--txt-color);
}

.layout__content {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;
}

.layout__content-main {
  padding: 30px;
}

@media screen and (max-width: 768px) {
  .layout__content {
    width: 100%;
    padding-left: 0;
  }
  .layout__content-main {
    padding: 5px;
  }
}
