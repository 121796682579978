.sidebar {
  top: 0;
  left: 0;
  position: fixed;
  max-height: 100vh;
  min-height: 100vh;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: scroll !important;
  box-shadow: var(--box-shadow);
  min-width: var(--sidebar-width);
  background-color: var(--main-bg);
}

.sidebar__logo {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar__logo > img {
  height: 70px;
}

.sidebar__item {
  padding: 0 20px;
  position: relative;
}

.sidebar__item-inner {
  padding: 15px 25px;
  display: flex;
  align-items: center;
  font-weight: 600;
  position: relative;
  transition: color 0.3s ease 0s;
}

.sidebar__item-badge {
  right: 10px;
  width: 25px;
  height: 25px;
  padding: 5px;
  display: flex;
  color: white;
  font-weight: 600;
  font-size: 0.8rem;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
}

.sidebar__item-inner > i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.sidebar__item-inner > span {
  text-transform: capitalize;
}

.sidebar__item-inner:hover {
  color: var(--main-color);
}

.sidebar__item-inner.active {
  border-radius: var(--border-radius);
  background-image: linear-gradient(
    to right,
    var(--main-color),
    var(--second-color)
  );
  color: var(--txt-white);
}

@media screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
